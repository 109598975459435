import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import { showSuccessToast, showFailToast } from 'vant';
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Grid, GridItem } from 'vant';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
export default {
  props: {
    lang_list: {},
    lang: {},
    user: {},
    active: 1
  },
  data() {
    return {
      search_value: "",
      ce_show: false
    };
  },
  methods: {
    show_ce(lang) {
      this.ce_show = lang;
    },
    copyToClipboard() {
      if (this.user.code == null || this.user.code == undefined) {
        showFailToast(this.lang.ap8);
        return;
      }
      navigator.clipboard.writeText(this.user.code).then(() => {
        showSuccessToast(this.lang.ap9);
      }).catch(err => {
        showFailToast(this.lang.ap10);
      });
    },
    to: function (id1) {
      let _this = this;
      _this.$router.push({
        name: id1,
        query: {
          id: 0
        }
      });
    },
    chat: function (type) {
      api.chat(type);
    },
    shou: function (id = 0) {
      api.shou(id);
    },
    go_shou: function () {
      let _this = this;
      _this.$router.push({
        name: 'collection',
        query: {
          id: 0
        }
      });
    },
    go_user: function () {
      let _this = this;
      _this.$router.push({
        name: 'user',
        query: {
          id: 0
        }
      });
    },
    go_order: function () {
      let _this = this;
      _this.$router.push({
        name: 'order',
        query: {
          id: 0
        }
      });
    },
    out: function () {
      local.deleteInfo('jwt');
      showToast(lang.tp9);
    },
    go_cart: function () {
      let _this = this;
      _this.$router.push({
        name: 'cart',
        query: {
          id: 0
        }
      });
    },
    go_login: function () {
      let _this = this;
      _this.$router.push({
        name: 'login',
        query: {
          id: 0
        }
      });
    },
    out: function () {
      let _this = this;
      local.deleteInfo('jwt');
      _this.$router.push({
        name: 'login',
        query: {
          id: 0
        }
      });
    }
  }
};